<template>
  <div>
    <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
    <TitleWrapper
      :filter-option="true"
      title="PROMO_MAN"
      tooltip-title="PROMO_MAN"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <div v-if="showStudentsList" :key="forceRerender">
        <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
        <div v-else-if="!isLoading && isEmpty(studentList)">
          <NoRecordFound />
        </div>
        <div v-else class="mt-5 rounded-lg relative border border-primary-grey">
          <div
            id="table-container"
            :class="[leftBlurArea, rightBlurArea]"
            class="overflow-x-auto sm:overflow-hidden"
            @scroll.passive="scrollDetect"
          >
            <div>
              <table class="w-full table-auto whitespace-nowrap border-primary-grey">
                <thead class="bg-bright-gray">
                  <tr class="text-left">
                    <th
                      v-if="showStudentListbasedOnToggle"
                      class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                    >
                      <span
                        class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                      >
                        Is Promoted
                      </span>
                    </th>
                    <th
                      class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                    >
                      <span
                        class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                      >
                        Student Name
                      </span>
                    </th>
                    <th
                      v-if="promoteMode"
                      class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                    >
                      <span
                        class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                      >
                        Pass Subjects
                      </span>
                    </th>
                    <th
                      v-if="promoteMode"
                      class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                    >
                      <span
                        class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                      >
                        Fail Subjects
                      </span>
                    </th>
                    <th
                      class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                    >
                      <span
                        class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                      >
                        Result
                      </span>
                    </th>
                    <th
                      class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                    >
                      <span
                        class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                      >
                        Moved To
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(student, index) in studentList"
                    :key="index"
                    class="border-t border-primary-grey text-left"
                  >
                    <td
                      v-if="showStudentListbasedOnToggle"
                      class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right px-4 flex gap-2.5 items-center"
                    >
                      <div>
                        <UiCheckbox
                          :model-value="student.isChecked"
                          type="checkbox"
                          :name="`check-${index}`"
                          class="-mt-2"
                          value="isChecked"
                          @change="updateCheckList(index, $event)"
                        />
                      </div>
                      <div class="font-roboto font-normal text-text-color text-sm">
                        {{ index + 1 }}
                      </div>
                    </td>
                    <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3">
                      <span
                        class="flex items-center font-roboto font-normal text-text-color text-sm"
                      >
                        <img
                          class="mr-4 p-2 rounded-full bg-red-100"
                          src="@assets/images/admin/student.svg"
                          alt="student"
                        />
                        {{ student.full_name }}
                      </span>
                    </td>
                    <td
                      v-if="promoteMode"
                      class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3"
                    >
                      <span class="font-roboto font-normal text-text-color text-sm">
                        {{ student.pass_count }}
                      </span>
                    </td>
                    <td
                      v-if="promoteMode"
                      class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3"
                    >
                      <span class="font-roboto font-normal text-text-color text-sm">
                        {{ student.fail_count }}
                      </span>
                    </td>
                    <td
                      v-if="!promoteMode"
                      :key="student.id"
                      class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 pt-5 w-1/4"
                    >
                      <div class="xl:w-2/3 w-full flex gap-2.5 text-lg">
                        <UiCheckbox
                          type="radio"
                          :name="`${student.id}.status`"
                          :circle-shape="true"
                          value="pass"
                          label="Pass"
                          @change="updateStatus(index, $event)"
                        />
                        <UiCheckbox
                          type="radio"
                          :name="`${student.id}.status`"
                          :circle-shape="true"
                          value="fail"
                          label="Fail"
                          @change="updateStatus(index, $event)"
                        />
                      </div>
                    </td>
                    <td
                      v-else
                      class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-5 w-1/4"
                    >
                      <div
                        :class="
                          student.status === 'pass'
                            ? 'text-primary-green bg-primary-green-trans'
                            : 'text-text-color-danger bg-light-red'
                        "
                        class="font-roboto text-center w-20 py-2 px-4 rounded-md font-normal text-xs relative cursor-pointer"
                      >
                        {{ student.status }}
                      </div>
                    </td>
                    <td
                      :key="forceRerender1"
                      class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3"
                    >
                      <span class="font-roboto font-normal text-text-color text-sm">
                        {{
                          student.movedSection && student.movedSection.length
                            ? student.movedSection[0].title
                            : ''
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <SwitchMode @switch="switchPromotionMode" />
        <transition name="slide">
          <div v-if="promoteMode">
            <UiSingleSelect
              v-model="examID"
              title="Exam"
              label="title"
              class="flex-1"
              :options="examsList"
              reduce="id"
              rules="required"
              @change="getExamBasedStudent(examID)"
            />
          </div>
        </transition>
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-xl opacity-100">Pass Student Promote To</div>
        </div>
        <!-- <ValidationObserver tag="div"> -->
        <div class="mt-7">
          <div class="flex lg:gap-3 flex-col lg:flex-row">
            <UiSingleSelect
              v-model="promotion.classId"
              :options="classesList"
              title="Class"
              label="title"
              class="flex-1"
              reduce="id"
              rules="required"
              @change="setSectionsListForPromotion(promotion.classId)"
              @receiveList="setSectionsListForPromotion"
            />
            <UiSingleSelect
              v-model="promotion.sectionId"
              title="SECTS"
              :options="sectionListForPromotion"
              label="title"
              class="flex-1"
              reduce="id"
              rules="required"
            />
          </div>
        </div>
        <!-- </ValidationObserver> -->
        <div class="my-7 border border-border-line"></div>
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-xl opacity-100">Fail Student Demote To</div>
        </div>
        <!-- <ValidationObserver tag="div"> -->
        <div class="mt-7">
          <div class="flex lg:gap-3 flex-col lg:flex-row">
            <UiSingleSelect
              v-model="demotion.classId"
              :options="classesList"
              title="Class"
              label="title"
              class="flex-1"
              reduce="id"
              rules="required"
              @change="setSectionsListForDemotion(demotion.classId)"
              @receiveList="setSectionsListForDemotion"
            />
            <UiSingleSelect
              v-model="demotion.sectionId"
              title="SECTS"
              :options="sectionsListForDemotion"
              label="title"
              class="flex-1"
              reduce="id"
              rules="required"
            />
          </div>
        </div>
        <!-- </ValidationObserver> -->

        <div v-if="promoteAllStudent">
          <div class="my-7 border border-border-line"></div>
          <div class="flex justify-between">
            <div class="font-roboto text-text-color text-xl opacity-100">Other Student move to</div>
          </div>
          <!-- <ValidationObserver tag="div"> -->
          <div class="mt-7">
            <div class="flex lg:gap-3 flex-col lg:flex-row">
              <UiSingleSelect
                v-model="other.classId"
                :options="classesList"
                title="Class"
                label="title"
                class="flex-1"
                reduce="id"
                rules="required"
                @change="setSectionsListForOtherStudent(other.classId)"
                @receiveList="setSectionsListForOtherStudent"
              />
              <UiSingleSelect
                v-model="other.sectionId"
                title="Sections"
                :options="sectionsListForOtherStudents"
                label="title"
                class="flex-1"
                reduce="id"
                rules="required"
              />
            </div>
          </div>
          <!-- </ValidationObserver> -->
        </div>

        <div class="my-7 border border-border-line"></div>
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-xl opacity-100">Promote/Demote to</div>
          <div class="flex items-center gap-5">
            All
            <ToggleButton
              v-model="showStudentListbasedOnToggle"
              :is-status-hide="true"
              @setCheckboxVal="switchPromotionList"
            />
            Specific
          </div>
        </div>
      </div>
      <div class="my-7 border border-border-line"></div>
      <div v-if="showStudentsList" class="mt-10 flex justify-between">
        <button
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-12 px-10 flex justify-center items-center primary-button-outline"
          :disabled="isLoading"
          @click="goBack()"
        >
          <span>Previous</span>
        </button>
        <button
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-12 px-10 flex justify-center items-center primary-button"
          :disabled="isLoading"
          @click="handleSubmit(studentPromoted(invalid))"
        >
          <span>Promote</span>
        </button>
      </div>
      <div v-else class="mt-10 flex justify-between items-center">
        <div>
          <ToggleButton v-model="isSectionClosed" :is-status-hide="true" heading="Close Section" />
        </div>
        <button
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-12 px-10 flex justify-center items-center primary-button"
          :disabled="isLoading"
          @click="handleSubmit(toggleStudentList(invalid))"
        >
          <span>Next</span>
        </button>
      </div>
    </div>
    <UIConfirmationModal
      message="Are you sure you want to close this section?"
      button-text="Deactivate"
      :modal="confirmation"
      @cancel="toggleConfirmationModal"
      @confirm="closeSection"
    />
    <!-- </ValidationObserver> -->
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import GeneralUtil from '@src/mixins/general-mixins.js'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { isEmpty } from 'lodash'
import Loader from '@components/BaseComponent/Loader.vue'
import SwitchMode from '@src/router/views/promotion/SwitchPromotionMode.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'

export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    UiCheckbox,
    Loader,
    // ValidationObserver,
    SwitchMode,
    ToggleButton,
    UIConfirmationModal,
    NoRecordFound,
  },
  mixins: [GeneralUtil, scrollMixin],
  page: {
    title: 'Student Promotion',
    meta: [
      {
        name: 'description',
        content: 'Manage promotion of student ',
      },
    ],
  },
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      options: ['Select'],
      tHeads: ['Std Id', 'Student Name'],
      examData: [
        {
          start_date: '',
          end_date: '',
        },
      ],
      isLoading: false,
      modalButtonLoading: false,
      studentList: null,
      studentCounts: 0,
      promotion: {
        classId: '',
        sectionId: '',
      },
      demotion: {
        classId: '',
        sectionId: '',
      },
      other: {
        classId: '',
        sectionId: '',
      },
      sectionListForPromotion: [],
      sectionsListForDemotion: [],
      sectionsListForOtherStudents: [],
      promoteMode: false,
      examsList: [],
      examID: '',
      showStudentListbasedOnToggle: false,
      showStudentsList: false,
      promoteAllStudent: false,
      isSectionClosed: false,
      confirmation: false,
      forceRerender: 1,
      forceRerender1: 1,
    }
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope.id,
      campusId: (state) => state.layout.currentCampusScope.id,
      classesList: (state) => state?.classes?.classesList || [],
      showUiModal: (state) => state.layout.showUiModal,
    }),
  },
  watch: {
    classId: {
      deep: true,
      handler() {
        this.filterRecord()
      },
    },
    promoteMode: {
      immediate: true,
      handler(value) {
        if (value) this.getExams()
        else this.filterRecord()
      },
    },
    showUiModal: {
      handler(value) {
        this.toggleConfirmationModal()
      },
    },
  },
  mounted() {
    this.setRightBar()
    this.getCampusClasses()
  },
  methods: {
    getCampusClasses() {
      this.getClassesList({
        campus_id: this.campusId,
      })
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
    },
    toggleStudentList(invalid) {
      if (invalid) return
      this.showStudentsList = true
      if (!this.showStudentListbasedOnToggle)
        this.studentList.forEach((student) => {
          student.isChecked = 'isChecked'
        })
    },
    closeSection() {
      this.closeCurrentSection(this.classId)
        .then(() => {
          this.$store.commit(
            'toast/NEW',
            { type: 'success', message: 'Section Closed' },
            { root: true },
          )
          this.confirmation = false
          this.$router?.push({ name: 'quick-actions' })
          this.getClassesList()
          this.setcurrentSelectedSection('')
        })
        .catch((error) => {
          this.$store.commit('toast/NEW', { type: 'error', message: error.message }, { root: true })
        })
    },
    goBack() {
      this.showStudentsList = false
      this.forceRerender++
      this.studentList.forEach((student) => {
        student.isChecked = null
      })
    },
    switchPromotionList() {
      this.promoteAllStudent = !this.promoteAllStudent
    },
    setSectionsListForPromotion(id) {
      this.classesList.forEach((classItem) => {
        if (classItem.id === id) this.sectionListForPromotion = classItem.sections
      })
    },
    setSectionsListForDemotion(id) {
      this.classesList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsListForDemotion = classItem.sections
      })
    },
    setSectionsListForOtherStudent(id) {
      this.classesList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsListForOtherStudents = classItem.sections
      })
    },
    filterCheckedStudents() {
      let students = []
      this.studentList.forEach((student) => {
        if (student.isChecked === 'isChecked')
          students.push({
            student_id: student.id || student.student_id,
            promoted_status: student?.promoted_status || student?.status || 'other',
            is_moved_out: false,
          })
      })
      return students
    },
    checkEmptyStatus(students) {
      let status = students.every((student) => student.promoted_status !== 'other')
      return status
    },
    studentPromoted(invalid) {
      if (invalid) return
      let filterdStudentList = this.filterCheckedStudents()
      if (!this.showStudentListbasedOnToggle && !this.promoteMode) {
        let checkStatus = this.checkEmptyStatus(filterdStudentList)
        if (!checkStatus) {
          this.$store.commit(
            'toast/NEW',
            { type: 'error', message: 'Mark all status' },
            { root: true },
          )
          return
        }
      }
      let payload = {
        promoted_up_section_id: this.promotion.sectionId,
        promoted_down_section_id: this.demotion.sectionId,
        promoted_other_section_id: this.other?.sectionId || '',
        promoted_students: filterdStudentList,
        is_section_closed: this.isSectionClosed,
      }
      this.promoteStudents(payload).then((response) => {
        this.$store.commit(
          'toast/NEW',
          { type: 'success', message: response.data.message },
          { root: true },
        )
        this.$router?.push({ name: 'Students' })
      })
    },
    updateStatus(idx, e) {
      this.studentList[idx].promoted_status = e.value
      if (this.studentList[idx].promoted_status === GENERAL_CONSTANTS.PASS) {
        this.studentList[idx].movedSection = this.sectionListForPromotion.filter((section) => {
          return section.id === this.promotion.sectionId
        })
      } else if (this.studentList[idx].promoted_status === GENERAL_CONSTANTS.FAIL) {
        this.studentList[idx].movedSection = this.sectionsListForDemotion.filter((section) => {
          return section.id === this.demotion.sectionId
        })
      }
      this.forceRerender1++
    },
    updateCheckList(idx, e) {
      this.studentList[idx].isChecked = e.value
    },
    switchPromotionMode(val) {
      this.promoteMode = !this.promoteMode
    },
    filterRecord(range) {
      this.studentList = null
      this.isLoading = true
      this.loadClassStudents({
        campus_id: this.campusId,
        section_id: this.classId,
        role_id: 'section_student',
      })
        .then((res) => {
          this.studentList = res.records
          if (this.studentList.length > 0)
            this.studentList.forEach(
              (record) => (record.full_name = `${record.first_name} ${record.last_name}`),
            )
          this.studentCounts = res.meta.counts
          this.setRightBar(this.studentCounts)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setRightBar(totalStudents = 0, pass = 0, fail = 0) {
      this.setRightbarContent({
        header: {
          title: !this.promoteMode ? 'Promote Manually' : 'Promote Automatically',
          buttons: [
            {
              title: 'Close Section',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal', payload: {} },
            },
          ],
        },
      })
    },
    getExams() {
      this.studentList = null
      this.isLoading = true
      this.getPublishedExamsForPromotions()
        .then((res) => {
          this.examsList = res?.data.records
          this.setRightBar()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getExamBasedStudent(id) {
      let payload = {
        id: id,
      }
      this.getPublishedExamsStudents(payload).then((res) => {
        this.studentList = res.data.records
        this.setRightBar(this.studentList.length)
        this.studentList.forEach((student) => {
          student.full_name = `${student.first_name} ${student.last_name}`
        })
      })
    },
    ...mapActions('layout', ['setRightbarContent', 'setcurrentSelectedSection']),
    ...mapActions('student', [
      'promoteStudents',
      'loadClassStudents',
      'getPublishedExamsForPromotions',
      'getPublishedExamsStudents',
      'closeCurrentSection',
    ]),
    ...mapActions('classes', ['getClassesList']),
  },
}
</script>

<style lang="scss" module scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>

<template>
  <div class="mb-5">
    <div class="flex justify-end">
      <div class="flex items-center gap-5">
        Manually
        <ToggleButton
          v-model="promoteMode"
          :is-status-hide="true"
          @setCheckboxVal="$emit('switch', promoteMode)"
        />
        Automatically
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'

export default {
  components: { ToggleButton },
  emits: ['switch'],
  data() {
    return {
      promoteMode: false,
    }
  },
}
</script>
